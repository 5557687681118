import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '@nationwide/internet-servicing-contentful';
import { LoggerService, PolicyDataService, PolicyChangeService, PolicyChangeDataService } from '@nationwide/dgs-internet-servicing-policy-common';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-personal-policy-error-500',
    templateUrl: './error-500.component.html'
})
export class Error500Component implements OnInit {
    ENV_PROPERTIES = environment;
    object = Object;
    tableContents = ['contactUsTech', 'contactUsGeneral', 'contactUsEmail', 'contactUsClaims', 'contactUsBillingPolicy'];

    // eslint-disable-next-line max-params
    constructor(
        private LOGGER: LoggerService,
        private policyDataService: PolicyDataService,
        private policyChangeService: PolicyChangeService,
        private policyChangeDataService: PolicyChangeDataService,
        public contentfulService: ContentfulService
    ) { }

    ngOnInit(): void {
        this.LOGGER.error('Error 500', { previousUrl: this.policyDataService.previousUrl, changeList: this.policyChangeService.getChangeList(), pcError: this.policyChangeDataService.pcError });
        this.LOGGER.logCurrentContents();
    }
}
