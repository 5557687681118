<h3 class="error-page-popular-links">
    <is-interpolate [parent]="errorPageBodySectionComponent" [text]="popularLinksHeader">
    </is-interpolate>
</h3>
<is-interpolate class="error-page-popular-links" [parent]="errorPageBodySectionComponent" [text]="popularLinks">
</is-interpolate>

<div id="contactUs" class="mb0px section-header-table group contact-us-header">
    <h2>
        <is-interpolate [parent]="errorPageBodySectionComponent" [text]="contactUsHeader"></is-interpolate>
    </h2>
</div>
<div>
    <table class="table bolt-table bolt-table-vibrant-blue" aria-describedby="contactUs">
        <th>Reason</th>
        <th>Contact method</th>
        <tbody>
            <tr *ngFor="let row of tableComponents">
                <td class="equal-width">
                    <is-interpolate [parent]="errorPageBodySectionComponent" [text]="contentfulService.getContentfulContentById(row + 'Column1')">
                    </is-interpolate>
                </td>
                <td class="equal-width">
                    <is-interpolate [parent]="errorPageBodySectionComponent" [text]="contentfulService.getContentfulContentById(row + 'Column3')">
                    </is-interpolate>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="section-header-without-left-right group">
    <h2 class="privacy-security-header">
        <is-interpolate [parent]="errorPageBodySectionComponent" [text]="privacySecurityHeader"></is-interpolate>
    </h2>
</div>
<div class="section-body privacy-security-body">
    <is-interpolate [parent]="errorPageBodySectionComponent" [text]="privacySecurity">
    </is-interpolate>
</div>