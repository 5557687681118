
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoggerService, PROPERTY_CONSTANTS, PolicyChangeService, PageUtilsService, PolicyDataService } from '@nationwide/dgs-internet-servicing-policy-common';

@Injectable()
export class LoggingInterceptorService implements HttpInterceptor {
    apiNames = {
        ...environment.APIs,
        ...environment.SERVICE,
        ...environment.DOCUMENTS,
        pcSystemClockDate: environment.pcSystemClockDate,
        gisURL: environment.gisURL,
        permissionFlags: 'permission-flags',
        tlexpGetPolicyChanges: 'internetservicingpolicy-tlexp/v1/auto-policies/policy-changes',
        homeownerGetPolicyChanges: 'internetservicing-policy/v1/homeowners/policy-changes',
        latLong: environment.latLong,
        personalComponentExperience: 'personal-component-experience/v1/'
    };
    apiStartTimes = {};

    // eslint-disable-next-line max-params
    constructor(
        private LOGGER: LoggerService,
        private policyChangeService: PolicyChangeService,
        private policyDataService: PolicyDataService,
        private pageUtilsService: PageUtilsService
    ) {
        this.apiNames.experienceAutoServicing = this.apiNames.experienceAutoServicing.slice(0, -1);
        delete this.apiNames.apigeeEnvironment;
    }

    getAPIName(url: string, method: string, body): string {
        for (const property in this.apiNames) {
            if (url.includes(this.apiNames[property])) {
                return this.getAPINameFromMatchingList({ url, method, body, property });
            } else if (property === 'rtbcEndpoint' && url.includes('bill-calculator')) {
                return property;
            }
        }
        return PROPERTY_CONSTANTS.unknownAPI;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const excluded = RegExp(PROPERTY_CONSTANTS.excludedAPIIntercepts.join('|')).test(request.url);
        if (excluded) {
            return next.handle(request).pipe(catchError((errorResponse) => {
                if (request.url.includes('client-logging')) {
                    this.LOGGER.error('Client logging call Failed', errorResponse);
                }
                return throwError(() => errorResponse);
            }));
        } else {
            const apiName = this.getAPIName(request.url, request.method, request.body);
            this.logAPIRequest(apiName, request);
            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.logAPIResults(apiName, request, event);
                    }
                    return event;
                }),
                catchError((errorResponse) => {
                    this.logAPIError(errorResponse, apiName);
                    return throwError(() => errorResponse);
                })
            );
        }
    }

    private createPolicyData(event): any {
        let policyData;
        if (event.body?.result) {
            policyData = event.body.result;
        } else if (event.body?.savePolicyData) {
            policyData = event.body.savePolicyData;
        }
        return policyData;
    }

    private createPolicyDataSummary(event): any {
        const policyData = this.createPolicyData(event);
        if (policyData) {
            return policyData;
        } else if (event.body && !event.body.documentByteStream && !event.body.updateCoverages) {
            return event.body;
        } else {
            return {};
        }
    }

    private getAPINameFromMatchingList({ url, method, body, property }: { url: string; method: string; property: string; body }): string {
        if (this.isExperienceProperty(property)) {
            return this.getDetailedExperienceAPIName(url, method, property);
        } else if (property === 'ebiApiEndpoint' && body) {
            return `${property} ${body.eventId}`;
        } else if (property === 'ctmApiEndpoint' && body?.interactionGrouping) {
            return `${property} ${body.interactionGrouping.mappingId}`;
        }
        return property;
    }

    private getAdditionalParams(apiName: string): { apiTime: number; changeList: Array<any>; changeType: string; currentPage: string } {
        let apiTime = -1;
        if (this.apiStartTimes[apiName]) {
            apiTime = (Date.now() - this.apiStartTimes[apiName]) / PROPERTY_CONSTANTS.MILLISECONDS_IN_SECOND;
            delete this.apiStartTimes[apiName];
        }
        const changeList = this.policyChangeService.getChangeList();
        const changeType = this.policyDataService.changeTypeArray ? this.policyDataService.changeTypeArray.join(',') : '';
        return { apiTime, changeList, changeType, currentPage: this.pageUtilsService.findPage() };
    }

    private getAutoPolicyChangeWithID(path: string, id: string): string {
        if (path === PROPERTY_CONSTANTS.experienceAPIs.experienceAutoServicing) {
            return PROPERTY_CONSTANTS.experienceAPIDraft;
        }
        return `${path}, id ${id}`;
    }

    private getDetailedExperienceAPIName(url: string, method: string, property: string): string {
        try {
            let apiName = '';
            const lastPathToken = url.match(PROPERTY_CONSTANTS.lastSlashURLRegex);
            if (lastPathToken) {
                apiName = this.updateApiNameWithLastPathToken({ apiName, lastPathToken, url, method });
            }
            if (!apiName) {
                apiName = this.getPolicyMethod(method, property);
            } else if (PROPERTY_CONSTANTS.experienceAPIs[property]) {
                apiName = `${PROPERTY_CONSTANTS.experienceAPIs[property]} - ${PROPERTY_CONSTANTS.experienceAutoServicingAPIVerbs[method]} ${apiName}`;
            }
            return apiName;
        } catch (error) {
            return `${method}`;
        }
    }

    private getEligibleDiscounts(url: string): string {
        let type = PROPERTY_CONSTANTS.vehicle;
        let idIndex = url.indexOf('vehicles/');
        if (idIndex === -1) {
            type = PROPERTY_CONSTANTS.driver;
            idIndex = url.indexOf('drivers/') + 'drivers/'.length;
        } else {
            idIndex += 'vehicles/'.length;
        }
        const id = url.substring(idIndex, url.lastIndexOf('/'));
        return `${type} ${PROPERTY_CONSTANTS.experienceAPIEligibleDiscounts}, ${id}`;
    }

    private getPolicyChange(path: string): string {
        return `${path}`;
    }

    private getPolicyChangeIdOrStartChange(url: string): string {
        if (url.includes('/policies/')) {
            return PROPERTY_CONSTANTS.experienceAPIPolicyChangeID;
        } else {
            return PROPERTY_CONSTANTS.experienceAPIStartPolicyChange;
        }
    }

    private getPolicyMethod(method: string, property: string): string {
        method = method === 'POST' ? 'GET' : method;
        if (PROPERTY_CONSTANTS.experienceAPIs[property]) {
            return `${PROPERTY_CONSTANTS.experienceAPIs[property]} - ${PROPERTY_CONSTANTS.experienceAutoAPIMethods[method]}`;
        }
        return PROPERTY_CONSTANTS.experienceAutoAPIMethods[method];
    }

    private hasDocumentByteStream(event): boolean {
        return event?.body?.documentByteStream != null;
    }

    private isAutoPolicyChangeWithID(id: string): boolean {
        return id != null;
    }

    private isEligibleDiscounts(path: string): boolean {
        return path === PROPERTY_CONSTANTS.experienceAPIEligibleDiscounts;
    }

    private isExperienceAPI(url: string): boolean {
        const experienceURLs = [environment.APIs.experienceAuto, environment.APIs.experienceAutoServicing];
        return experienceURLs.filter((experienceURL) => url.includes(experienceURL)).length > 0;
    }

    private isExperienceProperty(property: string): boolean {
        return PROPERTY_CONSTANTS.experienceAPIs[property] || PROPERTY_CONSTANTS.experienceAPIsV4[property];
    }

    private isPathPolicyChanges(path: string): boolean {
        return path === PROPERTY_CONSTANTS.experienceAPIs.experienceAutoServicing;
    }

    private isPathValid(path: string): boolean {
        return path.length > 1;
    }

    private isPolicyCenterAPI(url: string): boolean {
        const pcURLs = [environment.APIs.autoPolicies, environment.APIs.autoPoliciesServicing];
        return pcURLs.filter((pcURL) => url.includes(pcURL)).length > 0;
    }

    private logAPIError(errorResponse, apiName): void {
        if (errorResponse instanceof HttpErrorResponse) {
            try {
                const additionalParams = this.getAdditionalParams(apiName);
                this.LOGGER.error(`API error response - ${apiName}`, errorResponse, additionalParams);
            } catch (error) {
                this.LOGGER.error(`Error when logging API error response`, error);
            }
        }
    }

    private logAPIRequest(apiName, request): void {
        try {
            this.apiStartTimes[apiName] = Date.now();
            if (this.isExperienceAPI(request.url)) {
                this.LOGGER.info(`API request - ${apiName}`, (this.createPolicyDataSummary(request), this.logHeaders(request)));
            } else if (this.isPolicyCenterAPI(request.url)) {
                this.LOGGER.info(`API request - ${apiName}`, this.logHeaders(request));
            } else if (apiName === 'documentBytestreamEndpoint') {
                const requestToLog = JSON.parse(JSON.stringify(request.body));
                requestToLog.accessToken = '***';
                this.LOGGER.info(`API request - ${apiName}`, { body: requestToLog, url: request.url, headers: this.logHeaders(request) });
            } else {
                this.LOGGER.info(`API request - ${apiName}`, { body: request.body, url: request.url, headers: this.logHeaders(request) });
            }
        } catch (error) {
            this.LOGGER.error(`Error when logging API request`, error);
        }
    }

    private logAPIResults(apiName, request, event): void {
        try {
            const additionalParams = this.getAdditionalParams(apiName);
            if (this.isExperienceAPI(request.url)) {
                this.LOGGER.info(`API success response - ${apiName}.`, this.createPolicyDataSummary(event), additionalParams);
            } else if (this.isPolicyCenterAPI(request.url) || this.hasDocumentByteStream(event)) {
                this.LOGGER.info(`API success response - ${apiName}.`, additionalParams);
            } else {
                this.LOGGER.info(`API success response - ${apiName}.`, event, additionalParams);
            }
        } catch (error) {
            this.LOGGER.error(`Error when logging API success response`, error);
        }
    }

    private logHeaders(request: HttpRequest<Object>): Object {
        let headers = {};
        const keys: string[] = request.headers.keys();
        keys.forEach((key) => {
            headers = { ...headers, [key]: key === 'Authorization' ? '***' : request.headers.get(key) };
        });
        return headers;
    }

    private updateApiNameWithLastPathToken({ apiName, lastPathToken, url, method }: { apiName: string; lastPathToken: string[]; url: string; method: string }): string {
        if (this.isAutoPolicyChangeWithID(lastPathToken[2])) {
            apiName += this.getAutoPolicyChangeWithID(lastPathToken[1], lastPathToken[2]);
        } else if (this.isPathPolicyChanges(lastPathToken[1])) {
            apiName += this.getPolicyChangeIdOrStartChange(url);
        } else if (this.isEligibleDiscounts(lastPathToken[1])) {
            apiName += this.getEligibleDiscounts(url);
        } else if (this.isPathValid(lastPathToken[1])) {
            apiName += this.getPolicyChange(lastPathToken[1]);
        }
        return apiName;
    }
}
