<div id="bodyContent">
    <div class="page-container group" id="skip-main-content">
        <h1>System error</h1>
        <div class="padding-top-zero form-content">
            <is-personal-policy-alert-wrapper [id]="'errorWarningMessage'" [type]="'warning'" [text]="contentfulService.getContentfulContentById('warning500')"></is-personal-policy-alert-wrapper>
            <app-personal-policy-error-page-body-section [tableComponents]="tableContents" [popularLinksHeader]="contentfulService.getContentfulContentById('popularLinksHeader')"
                [popularLinks]="contentfulService.getContentfulContentById('popularLinks')" [contactUsHeader]="contentfulService.getContentfulContentById('contactUsHeader')"
                [privacySecurityHeader]="contentfulService.getContentfulContentById('privacySecurityHeader')" [privacySecurity]="contentfulService.getContentfulContentById('privacySecurityContent')">
            </app-personal-policy-error-page-body-section>
        </div>
    </div>
</div>
