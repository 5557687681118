import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppSharedService } from './app-shared.service';
import { PROPERTY_CONSTANTS } from '@nationwide/dgs-internet-servicing-policy-common';

@Injectable()
export class DestinationPageInterceptorService {
    constructor(
        private router: Router,
        private appSharedService: AppSharedService
    ) { }

    canActivate(): boolean {
        let destination = this.appSharedService.getHashParam('destinationPage', window.location.href);
        if (destination) {
            destination = decodeURIComponent(destination);
            this.router.navigate([destination]);
        } else {
            this.router.navigate([PROPERTY_CONSTANTS.autoPrefix + PROPERTY_CONSTANTS.error400]);
        }
        return false;
    }
}
