import { Injectable } from '@angular/core';

@Injectable()
export class AppSharedService {
    getHashParam(param: string, url: string): string {
        const result = this.getRegexResult(param, url);
        let paramValue;
        if (result) {
            paramValue = result[1];
        }
        return paramValue;
    }

    getRegexResult(param: string, argUrl: string): RegExpExecArray {
        const url = argUrl;
        const regex = RegExp(`${param}=(.*?)(&|$)`, 'g');
        return regex.exec(url);
    }

    isGuidInTheUrl(param: string, url: string): boolean {
        let flag = false;
        const result = this.getRegexResult(param, url);
        let parmKeyAndValuePair = '';
        let parmValue = '';
        if (result) {
            parmKeyAndValuePair = result[0];
            parmValue = result[1];
            flag = parmKeyAndValuePair && parmValue && parmKeyAndValuePair.includes(param);
        }

        return flag;
    }
}
