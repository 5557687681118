import { environmentCommon } from './environment.common';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --configuration=production` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
    ...environmentCommon,
    envName: 'st',
    env: 'st' /* localhost, ci, dev[n], it, st, pt, prod */
};
