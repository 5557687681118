import { DestinationPageInterceptorService } from './shared/destination-page-interceptor.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CAMInterceptorService } from './shared/cam-interceptor.service';
import { Error500Component } from './shared/components/error-500/error-500.component';
import { AuthSuccessInterceptorService } from './shared/auth-success-interceptor.service';
import { AuthErrorInterceptorService } from './shared/auth-error-interceptor.service';

const routes: Routes = [
    { path: 'access_token', canActivate: [AuthSuccessInterceptorService], component: Error500Component, loadChildren: (): any => import('./shared/error.module').then((module) => module.ErrorModule) },
    { path: 'id_token', canActivate: [AuthSuccessInterceptorService], component: Error500Component, loadChildren: (): any => import('./shared/error.module').then((module) => module.ErrorModule) },
    { path: 'error', canActivate: [AuthErrorInterceptorService], component: Error500Component, loadChildren: (): any => import('./shared/error.module').then((module) => module.ErrorModule) },
    {
        path: 'personal/policy',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => import('./auto/auto.module').then((module) => module.AutoModule)
    },
    {
        path: 'personal/property',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => import('./property/property.module').then((module) => module.PropertyModule)
    },
    {
        path: 'personal/powersports',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => import('./powersports/powersports.module').then((module) => module.PowersportsModule)
    },
    {
        path: 'personal/stub',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => import('./policy-stub/policy-stub.module').then((module) => module.PolicyStubModule)
    },
    {
        path: 'NWIESESSION',
        canActivate: [DestinationPageInterceptorService],
        loadChildren: (): any => import('./auto/auto.module').then((module) => module.AutoModule)
    },
    {
        path: 'quick',
        loadChildren: (): any => import('./quick-flow/quick-flow.module').then((module) => module.QuickFlowModule)
    },
    {
        path: 'ivr',
        loadChildren: (): any => import('./auto/ivr/ivr.module').then((module) => module.IvrModule)
    },
    {
        path: 'qe',
        loadChildren: (): any => import('./docusign-texting/docusign-texting.module').then((module) => module.DocusignTextingModule)
    },
    {
        path: 'id-card-texting',
        loadChildren: (): any => import('./id-texting/id-texting.module').then((module) => module.IdTextingModule)
    },
    {
        path: 'qid',
        loadChildren: (): any => import('./quick-id/quick-id.module').then((module) => module.QuickIdModule)
    },
    {
        path: 'personal/umbrella',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => import('./umbrella/umbrella.module').then((module) => module.UmbrellaModule)
    },
    {
        path: 'personal/dwelling-fire',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => import('./dwelling-fire/dwelling-fire.module').then((module) => module.DwellingFireModule)
    },
    { path: '**', redirectTo: 'personal/policy/error-404' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
