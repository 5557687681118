import { NgModule } from '@angular/core';
import { IframeWrapperComponent } from './shared/components/iframe-wrapper/iframe-wrapper.component';
import { WaitMessageWrapperComponent } from './shared/components/wait-message-wrapper/wait-message-wrapper.component';
import { OauthClientService } from './shared/oauth-client.service';
import { CommonModule } from '@angular/common';
import { IframeComponent } from './shared/components/iframe/iframe.component';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { PolicyCommonSharedModule, PolicyCommonPowersportsModule, PolicyCommonPropertyModule } from '@nationwide/dgs-internet-servicing-policy-common';
import { DynamicFormsModule } from '@nationwide/dgs-internet-servicing-dynamic-forms';
import { QuickAuthenticateService } from './quick-flow/shared/quick-authenticate.service';

@NgModule({
    imports: [
        CommonModule,
        InternetServicingAngularComponentsModule,
        PolicyCommonSharedModule,
        PolicyCommonPowersportsModule,
        PolicyCommonPropertyModule,
        DynamicFormsModule
    ],
    declarations: [
        WaitMessageWrapperComponent,
        IframeWrapperComponent,
        IframeComponent
    ],
    exports: [
        WaitMessageWrapperComponent,
        IframeWrapperComponent,
        IframeComponent
    ],
    providers: [
        OauthClientService,
        QuickAuthenticateService
    ]
})
export class AppLoadModule { }
