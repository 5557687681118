import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// eslint-disable-next-line @typescript-eslint/naming-convention
import Bolt from '@nationwide-bolt/bundle-core';

if (environment.production) {
    enableProdMode();
}

Bolt.initialize().then(() => {
    platformBrowserDynamic().bootstrapModule(AppModule);
});
