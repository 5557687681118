import { Component, Input } from '@angular/core';
import { ContentfulService } from '@nationwide/internet-servicing-contentful';

@Component({
    selector: 'app-personal-policy-error-page-body-section',
    templateUrl: './error-page-body-section.component.html',
    styleUrls: ['./error-page-body-section.component.scss']
})
export class ErrorPageBodySectionComponent {
    @Input() contactUsHeader;
    @Input() popularLinks;
    @Input() popularLinksHeader;
    @Input() privacySecurity;
    @Input() privacySecurityHeader;
    @Input() tableComponents = [];

    errorPageBodySectionComponent;
    object = Object;

    constructor(
        public contentfulService: ContentfulService
    ) {
        this.errorPageBodySectionComponent = this;
        this.contentfulService = contentfulService;
    }
}
