import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class IframeQueueService {
    private _inEvent: BehaviorSubject<string | void>;
    private _outEvent: BehaviorSubject<string | void>;

    constructor() {
        this._inEvent = new BehaviorSubject('');
        this._outEvent = new BehaviorSubject('');
    }

    clearQueueIn(): void {
        this._inEvent.next();
    }

    clearQueueOut(): void {
        this._outEvent.next();
    }

    listenForIframeQueueInEvent(): Observable<string | void> {
        return this._inEvent.asObservable();
    }

    listenForIframeQueueOutEvent(): Observable<string | void> {
        return this._outEvent.asObservable();
    }

    nextIn(url: string): void {
        this._inEvent.next(url);
    }

    nextOut(payload: string): void {
        this._outEvent.next(payload);
    }
}
