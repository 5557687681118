import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { TimeoutModalService, PROPERTY_CONSTANTS, LoggerService, PolicyDataService } from '@nationwide/dgs-internet-servicing-policy-common';

@Injectable()
export class InternetServicingPolicyErrorHandler implements ErrorHandler {
    constructor(
        // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
        private injector: Injector
    ) { }
    handleError(error): void {
        const timeoutModalService = this.injector.get(TimeoutModalService);
        const LOGGER = this.injector.get(LoggerService);
        const policyDataService = this.injector.get(PolicyDataService);
        try {
            LOGGER.error('ERROR HANDLER', { message: error.message, stack: error.stack, policyNumber: sessionStorage.getItem('policyNumber'), error });
            policyDataService.lastErrorHandlerLog = error.message;
            if (error.message.toLowerCase().includes(PROPERTY_CONSTANTS.accessToken)) {
                timeoutModalService.endUserSession('Error Handler contained access token');
            }
        } catch (e) {
            LOGGER.error('Exception in error handler', e);
        }
        LOGGER.logCurrentContents();
    }
}
