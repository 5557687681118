import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoggerService, PolicyDataService, PROPERTY_CONSTANTS, WaitModalService } from '@nationwide/dgs-internet-servicing-policy-common';
import { ContentfulService } from '@nationwide/internet-servicing-contentful';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-wait-message-wrapper',
    templateUrl: './wait-message-wrapper.component.html'
})
export class WaitMessageWrapperComponent implements OnInit, OnDestroy {
    @Input() expirationTime: number;
    @Input() loadComplete = false;
    @Input() parent: string;
    @Input() timeoutAmount: number;
    @Input() waitMessage: string;

    expirationID;
    private waitMessageChanges: Subscription;

    // eslint-disable-next-line max-params
    constructor(
        private LOGGER: LoggerService,
        public contentfulService: ContentfulService,
        private waitModalService: WaitModalService,
        private policyDataService: PolicyDataService
    ) { }

    ngOnDestroy(): void {
        if (this.waitMessageChanges) {
            this.waitMessageChanges.unsubscribe();
        }
        clearTimeout(this.expirationID);
        this.waitModalService.clearWaitMessages();
    }

    ngOnInit(): void {
        if (!this.expirationTime) {
            this.expirationTime = 40000; // 40 seconds
        }
        this.waitMessageChanges = this.waitModalService.waitMessageChanges$.subscribe((message: string) => {
            this.waitMessage = message || this.contentfulService.getContentfulContentById('defaultWaitMessage');
            this.setExpirationTime();
        });
    }

    private setExpirationTime(): void {
        clearTimeout(this.expirationID);

        if (this.waitMessage === this.contentfulService.getContentfulContentById('defaultWaitMessage')) {
            this.expirationID = setTimeout(() => {
                if (!this.loadComplete) {
                    this.LOGGER.error(`Wait spinner has been displaying for ${this.expirationTime / PROPERTY_CONSTANTS.MILLISECONDS_IN_SECOND} seconds`, { lastApiError: this.policyDataService.lastApiErrorLog, lastErrorHandlerLog: this.policyDataService.lastErrorHandlerLog });
                    this.LOGGER.logCurrentContents();
                }
            }, this.expirationTime);
        }
    }
}

