import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { PolicyNumberFormatterPipe } from '@nationwide/internet-servicing-angular-pipes';
import { environment } from '../../environments/environment';
import { PolicyChangeDataService, PolicyDataService, PROPERTY_CONSTANTS, PowersportsCommonService, LoggerService, PageUtilsService, PropertyCommonService, AutoPolicyAPIService, PolicyAPISharedService, PowersportsPolicyAPIService, PropertyPolicyAPIService } from '@nationwide/dgs-internet-servicing-policy-common';

@Injectable()
export class CAMInterceptorService {
    // eslint-disable-next-line max-params
    constructor(
        private router: Router,
        private policyNumberFormatterPipe: PolicyNumberFormatterPipe,
        private policyDataService: PolicyDataService,
        private policyChangeDataService: PolicyChangeDataService,
        private autoPolicyAPIService: AutoPolicyAPIService,
        private propertyPolicyAPIService: PropertyPolicyAPIService,
        private powersportsPolicyAPIService: PowersportsPolicyAPIService,
        private policyAPISharedService: PolicyAPISharedService,
        private powersportsCommonService: PowersportsCommonService,
        private propertyCommonService: PropertyCommonService,
        private LOGGER: LoggerService,
        private pageUtilService: PageUtilsService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.runCanActivate(route.queryParams, state.url);
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.runCanActivate(route.queryParams, state.url);
        return true;
    }

    runCanActivate(queryParams: Params, url: string): void {
        if (Object.keys(queryParams).length > 0) {
            this.savePolicyNumber(queryParams, url);
            if (url.includes('powersports')) {
                this.setPowersportsComponents(url);
            }
            const propertyRoute = url?.split('?')[0];
            const route = propertyRoute?.split('/');
            const propertyRoutes = ['homeowners', 'property-discounts', 'property-coverages', 'property-documents'];
            if (url.includes('condo') || url.includes('tenant') || route?.length > 0 && propertyRoutes.includes(route[route.length - 1])) {
                this.setPropertyComponents(url);
            }
            this.saveUsername(queryParams);
            this.saveGeneralQueryParams(queryParams);
            url = this.findCorrectUrlForNavigation(url);
            if (this.policyDataService.entryPoint == null) {
                this.policyDataService.entryPoint = url;
            }
            this.router.navigate([url], { replaceUrl: true });
        }
    }

    private clearAPIServiceCache(): void {
        this.autoPolicyAPIService.clearCache();
        this.propertyPolicyAPIService.clearCache();
        this.powersportsPolicyAPIService.clearCache();
        this.policyAPISharedService.clearCache();
    }

    private findCorrectUrlForNavigation(url: string): string {
        url = url.slice(0, url.indexOf('?'));
        // Temporary solution for direct links to change pages
        if (url.includes(PROPERTY_CONSTANTS.autoPrefix) && !this.policyDataService.isIVR) {
            const pagesThatShouldRedirect = PROPERTY_CONSTANTS.changePages.concat(PROPERTY_CONSTANTS.selectionPageWithDraft);
            const doesUrlIncludeForbiddenPage = pagesThatShouldRedirect.find((page: string) => url.includes(page));
            if (doesUrlIncludeForbiddenPage) {
                this.LOGGER.info('Rerouting page to driver-vehicle', { url });
                url = PROPERTY_CONSTANTS.autoPrefix + PROPERTY_CONSTANTS.driverVehicle;
            }
        }
        return url;
    }

    private getUserIdCookie(): string {
        let userName = '';
        const listOfCookies = document.cookie.split(';');
        listOfCookies.forEach((cookie) => {
            if (cookie.includes('MYACCOUNT_USER_SESSION')) {
                let userSessionCookieList = cookie.split('=');
                userSessionCookieList.splice(0, 1);
                userSessionCookieList = userSessionCookieList.join('=').split(':');
                userSessionCookieList.forEach((userSessionCookie) => {
                    if (userSessionCookie.includes('userid')) {
                        userName = userSessionCookie.split('=')[1];
                    }
                });
            }
        });
        return userName;
    }

    private saveGeneralQueryParams(queryParams: Params): void {
        this.saveValueToSession('billingAccountNumber', queryParams.bkey);
        this.saveValueToSession('camSessionId', queryParams.sid);
        this.saveValueToSession('pcEnv', queryParams.pcEnv);
        this.saveValueToSession('bypassExperienceApi', queryParams.bypassExperienceApi);
        this.saveValueToSession('useDevEnvironment', queryParams.useDevEnvironment);
        this.saveValueToSession('useStub', queryParams.useStub);
    }

    private savePolicyNumber(queryParams: Params, url: string): void {
        const policyNumber = this.policyNumberFormatterPipe.transform(queryParams.pkey);
        const existingPolicyNumber = sessionStorage.getItem('policyNumber');
        if (policyNumber && policyNumber !== existingPolicyNumber) {
            const sessionUniqueId = this.policyDataService.sessionUniqueId;
            this.policyDataService.clearCachedData();
            this.policyDataService.sessionUniqueId = sessionUniqueId;
            this.policyChangeDataService.clearAll();
            this.policyChangeDataService.clearCoveragesSharedData();
            this.clearAPIServiceCache();
            this.policyDataService.environment = environment;

            if (sessionStorage.getItem('policyHistory')) {
                JSON.parse(sessionStorage.getItem('policyHistory')).forEach((history) => {
                    if (!url.includes(history?.prefix)) {
                        this.saveValueToSession('policyNumber', policyNumber);
                        this.pageUtilService.windowReload();
                    }
                });
            }
        }

        this.saveValueToSession('policyNumber', policyNumber);
    }

    private saveUsername(queryParams: Params): void {
        let userName = this.getUserIdCookie();
        if (userName === '' && queryParams.userName) {
            userName = queryParams.userName;
        }
        this.saveValueToSession('UserName', userName);
    }

    private saveValueToSession(key: string, value: any): void {
        if (value != null) {
            sessionStorage.setItem(key, value);
        }
    }

    private setPowersportsComponents(routerParam: string): void {
        this.powersportsCommonService.setPowersportsProductType(routerParam);
        this.policyDataService.lineOfBusiness = PROPERTY_CONSTANTS.lineOfBusiness.powersports;
        this.policyDataService.powersportsBreadCrumb = `${this.policyDataService.productType === 'MSA' ? PROPERTY_CONSTANTS.powersportMSADesc : this.policyDataService.productType} policy`;
    }

    private setPropertyComponents(routerParam: string): void {
        this.propertyCommonService.setPropertyProductType(routerParam);
        this.policyDataService.lineOfBusiness = PROPERTY_CONSTANTS.lineOfBusiness.property;
        this.policyDataService.propertyBreadCrumb = `${this.policyDataService.productType === PROPERTY_CONSTANTS.condo ? 'Condominium' : this.policyDataService.productType} policy`;
    }
}
